import Vue from 'vue'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
import router from './router'
import App from './App.vue'
import Functions from './functions'
import Variable from './variable'
import { store } from './store/store'

import { VueMaskDirective } from 'v-mask'

// Component
import DrawerMenu from '@/components/com_drawer_menu.vue'
import ComboBox from '@/components/com_combobox.vue'
import GeneralTable from '@/components/com_general_table.vue'
import ObjectInfo from '@/components/com_object_info.vue'
import Profile from '@/components/com_profile.vue'
import Users from '@/components/com_users.vue'
import Print from '@/components/com_document_print.vue'
import Search from '@/components/com_search.vue'
import QuotationSearch from '@/components/com_quotation_search.vue'
import PolicyRisk from '@/components/com_policy_risk.vue'
import PolicyInt from '@/components/com_policy_interest.vue'
import PolicySource from '@/components/com_policy_source.vue'
import PolicyFD from '@/components/com_policy_feeduty.vue'
import ClaimSubro from '@/components/com_claim_subrogation.vue'
import ClaimExpense from '@/components/com_claim_expense.vue'
import Snackbar from '@/components/com_snackbar.vue'

// Scoring
import Scoring from '@/components/com_scoring.vue'
import ScoringDetail from '@/components/com_scoring_detail.vue'

// Todo
import Todo from '@/components/com_todo.vue'

//Plugin
import Offline from 'v-offline'
// Format Money
import Money from 'v-money'
// Format Vuetify Money
import "./plugins/vuetify-money.js"
// WysiWyg
import Wysywig from 'vue-quill-editor'
// Masking
import VueTheMask from 'vue-the-mask'
// Vue Tour
import VueTour from 'vue-tour'
// Vue Moment
import VueMoment from 'vue-moment'
import Chat from 'vue-beautiful-chat'
import VueDropzone from 'vue2-dropzone'
import VueCroppie from 'vue-croppie';

// Vue Json Excel
import JsonExcel from "vue-json-excel"

// Component Profile
import ProfileOrganization from '@/components/com_profile_organization.vue'
import ProfilePorto from '@/components/com_profile_porto.vue'
import ProfileCapital from '@/components/com_profile_capital.vue'
import Attachment from '@/components/com_attachment.vue'
import AttachmentList from '@/components/com_attachment_list.vue'

//currency format
import AppNumber from '@/components/com_number.vue'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import 'croppie/croppie.css' // import the croppie css manually

const plugins = {
  install () {
    Vue.functions = Functions
    Vue.prototype.$functions = Functions
  }
}
const apps = {
  install () {
    Vue.vars = Variable
    Vue.prototype.$vars = Variable
  }
}
Vue.prototype.$axios = Axios
Vue.use(plugins)
Vue.use(apps)
// Editor Wysywig
Vue.use(Wysywig)
// Format Money
Vue.use(Money)
Vue.use(AppNumber)
// Vue Masking
Vue.use(VueTheMask)
// Vue Tour
Vue.use(VueTour)
// Vue Chat
Vue.use(Chat)
// Vue Moment
Vue.use(VueMoment)

Vue.use(VueCroppie);
// Pintura
Vue.component('app-offline',Offline)
Vue.component('app-drawer_menu',DrawerMenu)
Vue.component('app-cb',ComboBox)
Vue.component('app-gt',GeneralTable)
Vue.component('app-oi',ObjectInfo)
Vue.component('app-profile',Profile)
Vue.component('app-users',Users)
Vue.component('app-print',Print)
Vue.component('vue-dropzone',VueDropzone)
Vue.component("vue-download-excel", JsonExcel)
Vue.component('app-search',Search)
Vue.component('app-quotation-search',QuotationSearch)
Vue.component('app-snackbar',Snackbar)

// Use Profile
Vue.component('app-profile-organization',ProfileOrganization)
Vue.component('app-profile-porto',ProfilePorto)
Vue.component('app-profile-capital',ProfileCapital)

// Use Attachment
Vue.component('app-attachment',Attachment)
Vue.component('app-attachment-list',AttachmentList)

// Modul Component Policy
Vue.component('app-policy-risk',PolicyRisk)
Vue.component('app-policy-interest',PolicyInt)
Vue.component('app-policy-source',PolicySource)
Vue.component('app-policy-feeduty',PolicyFD)

// Modul Component Claim
Vue.component('app-claim-subro',ClaimSubro)
Vue.component('app-claim-expense',ClaimExpense)

// Todo
Vue.component('app-todo',Todo)

// Scoring
Vue.component('app-scoring',Scoring)
Vue.component('app-scoring-detail',ScoringDetail)

Vue.component('app-number',AppNumber)

//Include CSS Custom
require('../src/assets/css/my.css')
require('../src/assets/css/pulse.css')
require('../src/assets/css/shake.css')

// CSS Vue Tour
require('vue-tour/dist/vue-tour.css')


Vue.directive('mask', VueMaskDirective);
//HIDE SHOW TAG
Vue.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


