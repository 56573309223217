<template>
  <div>
    <v-dialog
      v-model="dialogsearch"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-snackbar
        :value="snackbar.dialog"
        :timeout="snackbar.timeout"
        color="fourth"
        rounded="pill"
        top
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            dark
            text
            @click.native="remarks.dialog = true"
            small
            v-if="snackbar.color === 'error'"
            >Open</v-btn
          >
          <v-btn dark text @click.native="snackbar.dialog = false" small
            >Close</v-btn
          >
        </template>
      </v-snackbar>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click.stop="SearchClose()">
            <v-icon>{{ $vars.V("icon_back") }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ search_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="SearchGet">
            Search Data
            <v-icon>{{ $vars.V("icon_search") }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container grid-list-md class="pa-12">
          <v-layout row wrap>
            <template>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="quotation.id"
                    id="ticket_id"
                    prepend-inner-icon="list_alt"
                    label="ID"
                    autofocus
                    @keyup.enter="SearchGet"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="quotation.name"
                    id="ticket_name"
                    prepend-inner-icon="group"
                    label="Principal Name"
                    @keyup.enter="SearchGet"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="mt-3">
                  <app-cb
                    cb_type="subclass"
                    cb_url="apiListSubClass"
                    cb_url_parameter="subclass_actived=Y"
                    cb_title="Subclass"
                    cb_id="campaign_subclass"
                    cb_desc="campaign_subclass_desc"
                    cb_rules="Please fill Subclass"
                    cb_desc_readonly="readonly"
                    cb_items_id="subclass_id"
                    cb_items_desc="subclass_desc"
                    :cb_value_id="quotation.subclass"
                    :cb_value_desc="quotation.subclassdesc"
                    cb_items_additional_1="subclass_ujroh"
                    @clicked="GetSubClass"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="quotation.quotype" row>
                    <v-radio label="Semua Penawaran" value=""></v-radio>
                    <v-radio label="Penawaran Tanpa Rate" value="U"></v-radio>
                    <v-radio label="Penawaran Dengan Rate" value="K"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="quotation.keydesc" row>
                    <v-radio label="Semua Status" value=""></v-radio>
                    <v-radio label="New" value="N"></v-radio>
                    <v-radio label="Request Closing" value="U"></v-radio>
                    <v-radio label="Loss Business" value="L"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="header[search_title]"
                  :items="items[search_title]"
                  :loading="loading.quotation"
                  loading-text="Loading... Please wait"
                  dense
                  item-key="dataid"
                  class="elevation-1 row-pointer custom-table"
                >
                  <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                      <span slot="activator">
                        {{ props.header.text }}
                      </span>
                      <span>
                        {{ props.header.text }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data> No Data Available </template>
                  <template v-slot:item.ticket_sourceid="{ item }">
                    {{ item.ticket_sourceid }} - {{ item.ticket_sourcename }}
                  </template>
                  <template v-slot:item.ticket_picgender="{ item }">
                    {{ item.ticket_picgender }}
                  </template>
                  <template v-slot:item.ticket_picname="{ item }">
                    {{ item.ticket_picname }}<br>{{ item.ticket_pictitle }}<br>{{ item.ticket_mobile }}<br>{{ item.ticket_email }}
                  </template>
                  <template v-slot:item.ticket_id="{ item }">
                    {{ item.ticket_id }}<br />
                    <small
                      :class="
                        item.ticket_quotype === 'U'
                          ? 'primary--text'
                          : 'secondary--text'
                      "
                      ><b>Penawaran {{ item.ticket_quotypedesc }}</b></small
                    ><br />
                    <v-chip
                      :class="
                        item.ticket_key === 'L' ? 'red--text' : 'green--text'
                      "
                      outlined
                      x-small
                      ><b>{{ item.ticket_keydesc }}</b></v-chip
                    >
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div
                      @click="CBGet(item.ticket_id)"
                      class="cursor primary--text"
                    >
                      <small><u>Edit</u></small>
                    </div>
                    <div
                      @click="CBTodo(item.ticket_id)"
                      class="cursor secondary--text"
                    >
                      <small
                        ><u>Follow Up ({{ item.ticket_todocount }})</u></small
                      >
                    </div>
                    <div
                      @click="CBPrint(item.ticket_id, item.ticket_quotype)"
                      class="cursor primary--text"
                    >
                      <small><u>Print</u></small>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog> -->
    <app-print
      v-model="modal.print"
      modul="quotation"
      :id="quotation.printid"
      :refid="quotation.refid"
    ></app-print>
    <app-todo
      :dialog="todo.dialog"
      :title="todo.title"
      :id="todo.id"
      @close="todo.dialog = false"
    >
    </app-todo>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    search_title: String,
    src_id: String,
  },
  data: () => ({
    limit: "1000",
    header: {
      quotation: [
        { text: "Actions", value: "action", sortable: false, width: "100" },
        {
          text: "ID",
          value: "ticket_id",
          align: "left",
        },
        { text: "Sub Class", value: "ticket_subclassdesc" },
        { text: "Principal Name", value: "ticket_name" },
        { text: "Principal Address", value: "ticket_address" },
        { text: "Agen", value: "ticket_sourceid" },
        { text: "PIC Gender", value: "ticket_picgender" },
        { text: "PIC Name/Jabatan/No Hp/Email", value: "ticket_picname" },
      ],
    },
    items: [],
    loading: {
      quotation: false,
    },
    quotation: {
      id: "",
      name: "",
      subclass: "",
      subclassdesc: "",
      quotype: "",
      keydesc: "",
      printid: "",
      refid: "",
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
      ok_disabled: false,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    todo: {
      dialog: false,
      title: "",
      id: "",
    },
    modal: {
      print: false,
    },
  }),
  //   created() {

  //   },
  computed: {
    dialogsearch: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    SearchClose() {
      this.dialogsearch = false;
    },
    SearchGet() {
      if (this.search_title === "quotation") {
        this.loading.quotation = true;
        let formdata = {
          ticket_id: this.quotation.id,
          ticket_name: this.quotation.name,
          ticket_subclass: this.quotation.subclass,
          ticket_quotype: this.quotation.quotype,
          ticket_key: this.quotation.keydesc,
          order_by: "ticket_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListTicket"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.loading.quotation = false;
                this.items[this.search_title] = feedback;
              } else {
                this.items[this.search_title] = feedback;
                this.loading.quotation = false;
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              }
            } else {
              this.items[this.search_title] = feedback;
              this.loading.quotation = false;
              this.SnackBar(true, "error", "No Data Available", 0);
            }
          });
      }
    },
    CBGet(value) {
      this.$emit("clicked", value);
      this.SearchClose();
    },
    GetSubClass(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        this.quotation.subclass = splits[0];
        this.quotation.subclassdesc = splits[1];
      }
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    CBTodo(id) {
      this.todo.dialog = true;
      this.todo.title = "Follow Up";
      this.todo.id = id;
    },
    CBPrint(id, refid) {
      this.modal.print = true;
      this.quotation.printid = id;
      this.quotation.refid = refid;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>
