<template>
  <div id="app-profile-porto">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <v-card-title class="ml-n4">
        <v-btn
          :color="selected.length === items.length ? 'fourth' : ''"
          @click="SelectAllPage"
          class="mr-2"
          title="select all page"
          small
          >{{ $vars.V("txt_select_all_page") }}
        </v-btn>
        <v-btn
          color="error"
          title="delete selected"
          @click="Confirm('multidelete', '')"
          class="mr-2"
          v-if="selected.length > 0"
          :disabled="access.delete === 0 ? true : false"
          small
        >
          <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
        </v-btn>
        <v-btn @click="Add" class="mr-2" title="Add New" small
          >{{ $vars.V("txt_add") }}
        </v-btn>
        <v-btn @click="List(id)" class="mr-2" title="Refresh" small
          ><v-icon>{{ $vars.V("icon_refresh") }}</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          title="search"
          v-if="btn_search === false"
          @click="btn_search = true"
          small
        >
          <v-icon>{{ $vars.V("icon_search") }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          @click:append="btn_search = false"
          label="Search"
          single-line
          hide-details
          v-if="btn_search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :value="selected"
        :headers="headers"
        :items="items"
        :search="search"
        @input="Check($event)"
        item-key="profileporto_id"
        show-select
        class="elevation-1"
        :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        dense
        :loading="loading.detail"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.profileporto_obligee="{ item }">
          {{ item.profileporto_obligee }} - {{ item.profileporto_obligee_desc }}
        </template>
        <template v-slot:item.profileporto_status="{ item }">
          {{ item.profileporto_status_desc }}
        </template>
        <template v-slot:item.profileporto_amount="{ item }">
          {{ $functions.NewFormatNumber(item.profileporto_amount) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            @click="Edit(item.profileporto_id)"
            :disabled="access.edit === 0 ? true : false"
            title="edit"
          >
            {{ $vars.V("icon_edit") }}
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Portfolio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_porto"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_lob=01&profile_actived=Y"
                  :cb_title="$vars.V('txt_profileporto_obligee')"
                  cb_id="profileporto_obligee"
                  cb_desc="profileporto_obligee_desc"
                  :cb_rules="$vars.V('txt_profileporto_obligee')"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_fullname"
                  :cb_value_id="form.profileporto_obligee"
                  :cb_value_desc="form.profileporto_obligee_desc"
                >
                </app-cb>
              </v-col>
              <!-- Year -->
              <v-col cols="3" sm="3" md="3" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_year"
                  :label="$vars.V('txt_profileporto_year')"
                  background-color="numeric"
                  reverse
                  :rules="form.profileporto_year_rules"
                  :maxlength="maxinput.year"
                >
                </v-text-field>
              </v-col>
              <v-col cols="9" sm="9" md="9" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_projectname"
                  :label="$vars.V('txt_profileporto_projectname')"
                  :rules="form.profileporto_projectname_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_location"
                  :label="$vars.V('txt_profileporto_location')"
                  :rules="form.profileporto_location_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n2">
                <v-select
                  v-model="form.profileporto_status"
                  :items="detail.profileporto_status"
                  :label="$vars.V('txt_profileporto_status')"
                  dense
                  undelined
                  item-value="code_id"
                  item-text="code_desc"
                  return-object
                ></v-select>
              </v-col>
              <!-- CCY -->
              <v-col cols="4" sm="4" md="4" class="mt-n3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  :cb_title="$vars.V('txt_profileporto_ccy')"
                  cb_id="profileporto_ccy"
                  cb_desc="profileporto_ccydesc"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.profileporto_ccy"
                  :cb_value_desc="form.profileporto_ccydesc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>
              <!-- Project Amount -->
              <v-col cols="8" sm="8" md="8" class="mt-n6">
                <vuetify-money
                  v-model="form.profileporto_amount"
                  v-bind:options="formatamount"
                  :label="$vars.V('txt_profileporto_amount')"
                  :placeholder="$vars.V('txt_profileporto_amount')"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
          >
            {{ confirm.text }}
          </v-btn>
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Portofolio -->
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    url_type: "add",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    loading: {
      page: true,
      detail: true,
    },
    dialog: {
      detail: false,
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2,
    },
    properties: {
      reverse: true,
    },
    selected: [],
    items: [],
    btn_search: false,
    search: "",
    headers: [],
    form: {
      profileporto_id: "",
      profileporto_profileid: "",
      profileporto_obligee: "",
      profileporto_obligee_desc: "",
      profileporto_year: "",
      profileporto_year_rules: [(v) => !!v || "Please fill Year"],
      profileporto_projectname: "",
      profileporto_projectname_rules: [
        (v) => !!v || "Please fill Project Name",
      ],
      profileporto_location: "",
      profileporto_location_rules: [
        (v) => !!v || "Please fill Project Location",
      ],
      profileporto_ccy: "IDR",
      profileporto_ccydesc: "IDR",
      profileporto_amount: 0,
      profileporto_cuser: "",
      profileporto_cdate: "",
      profileporto_luser: "",
      profileporto_ldate: "",
      profileporto_status: "",
      profileporto_actived: true,
    },
    maxinput: {
      id: 20,
      desc: 150,
      year: 4,
    },
    detail: {
      profileporto_status: [],
    },
  }),
  watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata;
        this.List(newdata);
      } else {
        this.id = "";
        this.List(newdata);
      }
    },
  },
  created() {
    this.List(this.id);
  },
  mounted() {
    this.headers = [
      { text: this.$vars.V("txt_profileporto_id"), value: "profileporto_id" },
      {
        text: this.$vars.V("txt_profileporto_year"),
        value: "profileporto_year",
      },
      {
        text: this.$vars.V("txt_profileporto_obligee"),
        value: "profileporto_obligee",
      },
      {
        text: this.$vars.V("txt_profileporto_projectname"),
        value: "profileporto_projectname",
      },
      {
        text: this.$vars.V("txt_profileporto_location"),
        value: "profileporto_location",
      },
      {
        text: this.$vars.V("txt_profileporto_status"),
        value: "profileporto_status",
      },
      { text: this.$vars.V("txt_profileporto_ccy"), value: "profileporto_ccy" },
      {
        text: this.$vars.V("txt_profileporto_amount"),
        value: "profileporto_amount",
        align: "right",
      },
      { text: "Actions", value: "action", sortable: false },
    ];
  },
  methods: {
    Access() {
      let modul = "profile";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.form.profileporto_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    Write() {
      let profileporto_obligee = document.getElementById(
        "profileporto_obligee"
      ).value;
      let profileporto_ccy = document.getElementById("profileporto_ccy").value;
      let formdata = {
        url_type: this.url_type,
        profileporto_id: this.form.profileporto_id,
        profileporto_profileid: this.form.profileporto_profileid,
        profileporto_projectname: this.form.profileporto_projectname,
        profileporto_obligee: profileporto_obligee,
        profileporto_location: this.form.profileporto_location,
        profileporto_ccy: profileporto_ccy,
        profileporto_amount: String(this.form.profileporto_amount),
        profileporto_status: this.form.profileporto_status,
        profileporto_year: String(this.form.profileporto_year),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfilePorto"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog.detail = false;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.profileporto_cuser = feedback[0].feedback_users_id;
                this.form.profileporto_cdate = feedback[0].feedback_users_date;
              }
              this.List(this.id);
              this.form.profileporto_luser = feedback[0].feedback_users_id;
              this.form.profileporto_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        profileporto_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfilePorto"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.List(this.id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        });
    },
    Add() {
      this.PortofolioStatus();
      this.dialog.detail = true;
      this.url_type = "add";
      this.form.profileporto_obligee = "";
      this.form.profileporto_obligee_desc = "";
      this.form.profileporto_year = "";
      this.form.profileporto_projectname = "";
      this.form.profileporto_location = "";
      this.form.profileporto_ccy = "IDR";
      this.form.profileporto_amount = 0;
      setTimeout(function () {
        document.getElementById("profileporto_obligee").focus();
      }, 500);
    },
    Edit(id) {
      if (id !== "") {
        this.Add();
        this.loading.detail = true;
        let formdata = {
          profileporto_id: id,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListProfilePorto"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.dialog.detail = true;
                this.url_type = "edit";
                this.form.profileporto_id = feedback[0].profileporto_id;
                this.form.profileporto_profileid =
                  feedback[0].profileporto_profileid;
                this.form.profileporto_obligee =
                  feedback[0].profileporto_obligee;
                this.form.profileporto_obligee_desc =
                  feedback[0].profileporto_obligee_desc;
                this.form.profileporto_year = feedback[0].profileporto_year;
                this.form.profileporto_projectname =
                  feedback[0].profileporto_projectname;
                this.form.profileporto_location =
                  feedback[0].profileporto_location;
                this.form.profileporto_ccy = feedback[0].profileporto_ccy;
                this.form.profileporto_amount = feedback[0].profileporto_amount;
                this.form.profileporto_status = feedback[0].profileporto_status;
                this.form.profileporto_actived = this.$functions.TrueOrFalse(
                  feedback[0].profileporto_actived
                );
              } else {
                this.SnackBar(true, "error", feedback[0].feedback, "3000");
              }
            } else {
              this.SnackBar(true, "error", this.$functions.NoData(), "3000");
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    Confirm(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_porto.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.form.profileporto_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    List(id) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        this.form.profileporto_profileid = id;
        let formdata = {
          profileporto_profileid: id,
          order_by: "profileporto_profileid",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListProfilePorto"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
    GetProfile(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let address = splits[3];
        this.form.profileporto_refid = splits[0];
        this.form.profileporto_refdesc = splits[1];
        this.form.profileporto_name = splits[1];
        this.form.profileporto_refaddress = address;
      }
    },
    PortofolioStatus() {
      let formdata = {
        code_group: "PORTOSTATUS",
        code_actived: "Y",
        order_by: "code_desc",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.detail.profileporto_status = feedback;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
  },
};
</script>