<template>
  <v-text-field
    v-model="formattedvalue"
    v-bind="$attrs"
    :label="label"
    @input="onInput"
    @blur="onBlur"
    class="money-input"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "IDR",
    },
    value: {
      type: [String, Number],
      default: "0",
    },
    decimal: {
      type: Number,
      default: 15, // Default 15 desimal jika tidak diberikan
    },
  },
  data() {
    return {
      formattedvalue: "0",
    };
  },
  mounted() {
    this.formattedvalue = this.formatNumber(this.value);
  },
  methods: {
    formatNumber(value) {
      // Pastikan value diubah menjadi string
      const strValue = String(value);

      const [integerPart, decimalPart = ""] = strValue.split(".");
      const cleanedInteger = integerPart.replace(/\D/g, "");
      const formattedInteger = Number(cleanedInteger).toLocaleString("en-US");
      const cleanedDecimal = decimalPart.replace(/\D/g, "").slice(0, this.decimal);
      const paddedDecimal = cleanedDecimal.padEnd(this.decimal, "0");

      return this.decimal > 0 ? `${formattedInteger}.${paddedDecimal}` : formattedInteger;
    },
    onInput(value) {
      this.formattedvalue = value;
      const strValue = String(value); // Pastikan `value` selalu string sebelum split

      const [integerPart, decimalPart = ""] = strValue.split(".");
      const cleanedInteger = integerPart.replace(/\D/g, "");
      const cleanedDecimal = decimalPart.replace(/\D/g, "").slice(0, this.decimal);

      this.$emit("input", this.decimal > 0 ? `${cleanedInteger}.${cleanedDecimal || "0"}` : cleanedInteger);
    },
    onBlur() {
      this.formattedvalue = this.formatNumber(this.value);
    },
  },
};
</script>

<style scoped>
.money-input ::v-deep .v-text-field__slot input {
  text-align: right !important;
}
</style>
