<template>
    <div v-if="form.access === 1">
        <app-drawer_menu drawer_menu_title="Upload Certificate"></app-drawer_menu>
        <v-tabs
	      background-color="white"
	      color="fourth"
          ref="upload"
	    >
        <v-tab
            id       = "tabform"
            href   	 ="#tab-form"
        >
            Form
        </v-tab>
        <v-tab
        id       = "tablist"
        href   	 ="#tab-list"
        >
            List
        </v-tab>
        <v-tab-item
            value  ="tab-form"
        >
        <v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
        <!-- Loading Upload -->
        <h4>{{loading.upload}}</h4>
        <!-- Upload Hidden -->
        <input type="file" ref="fileupload" @change="DisplayFile($event)" style="display:none" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        <v-form enctype="multipart/form-data" ref="form_upload_profile" lazy-validation>
			<v-row class="mx-2 mt-2">
                <v-col cols="12" sm="12" md="12">
                    <app-cb
                    cb_type           	="campaign"
                    cb_url            	="apiListCampaign"
                    cb_url_parameter  	="campaig_actived=Y"
                    cb_title          	="Campaign"
                    cb_id             	="campaign_id"
                    cb_desc           	="campaign_campaigndesc"
                    cb_rules          	="Please fill Campaign"
                    cb_desc_readonly  	="readonly"
                    cb_items_id       	="campaign_id"
                    cb_items_desc     	="campaign_desc"
                    :cb_value_id		="form.campaign_id"
                    :cb_value_desc		="form.campaign_desc"
                    @clicked            ="GetCampaign"
                    >
                    </app-cb>
                </v-col>
				<v-col cols="12" sm="9" md="9" class="mt-n10">
                    <v-text-field
                    :value ="form.filename"
                    prepend-icon="mdi-file-excel"
                    @click="OpenUpload"
                    placeholder="Choose File Excel">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="mt-n10">
                    <v-select
                    v-model="sheetname"
                    :items="sheetlist"
                    label="Sheet Name"
                    prepend-icon="filter_list"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mt-n5">
                    <v-text-field
                    v-model="form.email"
                    label="Email"
                    prepend-icon="mdi-email"
                    @click:append="AddEmail"
                    @keyup.enter="AddEmail"
                    ></v-text-field>
                    <v-chip
                    v-for="(email, index) in emails"
                    :key="index"
                    color="fourth white--text"
                    @click="RemoveEmail(index)"
                    >
                    {{ email }}
                    <v-icon small>mdi-close</v-icon>
                    </v-chip>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mt-n5">
                    <v-switch
                        v-model="form.notifyme"
                        label="Notify Me in Telegram"
                        false-value="N"
                        true-value="Y"
                        hide-details
                    ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn
				      color		="primary"
				      class		="white--text"
				      rounded
                      :disabled = "loading.upload === 'Loading...'?true:false"
                      @click="Confirm"
				    >
				      <v-icon dark>upload</v-icon>Upload
				    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        </v-tab-item>
        <v-tab-item
            value  ="tab-list"
            class="mb-2"
        >
        <v-card>
            <v-container>
            <v-layout row wrap>
                <template>
                  <v-flex xs4>
                  <v-text-field
                      v-model="search.batchno"
                      id="batchno"
                      prepend-inner-icon="list_alt"
                      label="Batch No"
                      autofocus
                      @keyup.enter="SearchGet"
                  >
                  </v-text-field>
                  </v-flex>
                  <v-flex xs4>
                  <v-text-field
                      v-model="search.mapcode"
                      id="mapcode"
                      prepend-inner-icon="list_alt"
                      label="Map Code"
                      @keyup.enter="SearchGet"
                  >
                  </v-text-field>
                  </v-flex>
                  <v-flex xs4>
                  <v-text-field
                      v-model="search.campaignid"
                      id="campaign"
                      prepend-inner-icon="list_alt"
                      label="Campaign ID"
                      @keyup.enter="SearchGet"
                  >
                  </v-text-field>
                  </v-flex>
                  <v-btn
                @click="SearchGet"
                class="mb-2 primary"
                >
                <v-icon>{{$vars.V('icon_search')}}</v-icon>
                Search Data
                </v-btn>
                </template>
                <v-flex xs12>
                <v-data-table
                    :headers="header[search_title]"
                    :items="items[search_title]"
                    :loading="loading.certificate"
                    loading-text="Loading... Please wait"
                    dense
                    item-key="dataid"
                    class="elevation-1 row-pointer"
                >
                    <template v-slot:header.uploadheader_total="{ header }">
                        <div class="white--text"><br>Total<br>Success<br>Failed</div>
                    </template>
                    <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                        <span slot="activator">
                        {{ props.header.text }}
                        </span>
                        <span>
                        {{ props.header.text }}
                        </span>
                    </v-tooltip>
                    </template>
                    <template v-slot:no-data> No Data Available </template>
                    <template v-slot:item.action="{ item }">
                      <small v-if="item.uploadheader_failed > 0" @click="DownloadFailed(item.uploadheader_batchno)"><u class="cursor">download failed</u></small>
                    </template>
                    <template v-slot:item.uploadheader_cdate="{ item }">
                        {{$functions.FormatDateTimeSecond(item.uploadheader_cdate)}}
                    </template>
                    <template v-slot:item.uploadheader_batchno="{ item }">
                        {{item.uploadheader_batchno}}<br>{{item.uploadheader_cuser}}
                    </template>
                    <template v-slot:item.uploadheader_statusdesc="{ item }">
                        {{item.uploadheader_statusdesc}}<br>{{$functions.FormatDateTimeSecond(item.uploadheader_donedate)}}<br><small class="green--text font-weight-black">{{ $functions.TimeDiff(item.uploadheader_cdate,item.uploadheader_donedate) }}</small>
                    </template>
                    <template v-slot:item.uploadheader_total="{ item }">
                        {{item.uploadheader_total}}<br>{{item.uploadheader_success}}<br>{{item.uploadheader_failed}}
                    </template>
                    <template v-slot:item.uploadheader_emailto="{ item }">
                        <span v-html="$functions.FeedbackReplacer(item.uploadheader_emailto)"></span>
                    </template>
                    <template v-slot:item.uploadheader_attachment="{ item }">
                        <div v-html="$functions.CreateAnchorTags(item.uploadheader_attachment)"></div>
                    </template>
                </v-data-table>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card>
        </v-tab-item>
        </v-tabs>
        <!-- Dialog -->
        <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	    </v-dialog>
        <!-- Snackbar -->
        <v-snackbar
            v-model	= "snackbar.dialog"
            :timeout= "snackbar.timeout"
            color	= "fourth"
            rounded	= "pill"
            top
            style 	= "z-index: 9999"
            >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
            <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="confirm.dialog" max-width="490" persistent>
            <v-card>
                <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
                <v-card-text>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="dialog" color="primary"
                        :disabled   = "confirm.text === 'Ok' ? false:true"
                        @click      = "Loading">{{confirm.text}}</v-btn>
                    <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
    data: () => ({
	  	snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
		confirm: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		loading: {
          page: true,
          upload: '',
          list: false,
		  listlogs: false,
          certificate: false
		},
		form: {
			filename: '',
            campaign_id: '',
            campaign_desc: '',
            email: '',
            notifyme: '',
            access: 0
		},
        remarks: {
			feedback: "",
			dialog: false,
		},
        headername: [],
		sheetlist: [],
        sheetname: '',
        upload: '',
        inputEmail: '',
        emails: [],
        search_title: 'certificate',
        search: {
            batchno: ''
        },
        header: {
            certificate: [
                { text: "Action", value: "action", sortable: false},
                { text: "Date", value: "uploadheader_cdate" },
                { text: "Status", value: "uploadheader_statusdesc" },
                {
                text: "Batch No",
                value: "uploadheader_batchno",
                align: "left",
                },
                { text: "Map Code", value: "uploadheader_mapcode" },
                { text: "Campaign ID", value: "uploadheader_campaignid" },
                { text: "Path", value: "uploadheader_path" },
                { text: "Total Upload<br>Success<br>Failed", value: "uploadheader_total", align:"right" },
                { text: "Email", value: "uploadheader_emailto" },
                { text: "Attachment", value: "uploadheader_attachment" },
            ],
        },
        items: [],
    }),
    created () {
        this.Access()
    },
    methods: {
        //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "upload"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					if (priv[0].usersmenu_add === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					} else {
                        this.form.access = priv[0].usersmenu_add
                    }
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
        OpenUpload () {
            this.$refs.fileupload.click()
        },
        DisplayFile (e) {
            const file = e.target.files[0]
            var reader = new FileReader()
            reader.fileName = file.name
            let get = this
            get.sheetlist = []
            get.sheetname = ''
            get.loading.upload = 'Loading...'
            reader.onload = function (e) {
                var data = e.target.result
                var workbook = XLSX.read(data, { type: 'binary' })
                get.sheetlist = workbook.SheetNames
                get.form.filename = e.target.fileName
                get.loading.upload = e.target.fileName
            }
            this.upload = file
            reader.readAsBinaryString(file)
        },
        //Pop Up message for validating (Ok or Cancel) your proccess
		Confirm () {
            this.confirm.dialog = true
            this.confirm.title = "Upload"
            setTimeout(function() {
                document.getElementById("dialog").focus()
            }, 500)
		},
        Loading () {
			this.confirm.text = "Loading..."
			this.Upload()
		},
        Upload ()  {
            let form = new FormData()
            let campaignid 	=document.getElementById('campaign_id').value
            form.append('sheetname', this.sheetname)
            form.append('uploadfile', this.upload)
            form.append('campaign', campaignid)
            form.append('email', this.emails.toString())
            form.append('notifyme', this.form.notifyme)
            this.$axios.post(this.$functions.SafeURL('apiUploadPolicy', ``), form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
                let feedback = response.data
				if (feedback.length > 0) {
                    if (response.data[0].feedback === 'Y') {
                        this.form.campaign_id = ''
                        this.form.campaign_desc = ''
                        this.form.filename = ''
                        this.form.email = ''
                        this.loading.upload = ''
                        this.emails = []
                        this.sheetname = []
                        this.SnackBar(
							true,
							"primary",
							"Uploaded: "+feedback[0].feedback_id,
							0
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
                        this.$refs.upload.$el.querySelectorAll('.v-tab')[1].click()
                        this.search.batchno = feedback[0].feedback_id
                        this.SearchGet()
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog 	= true
                        this.remarks.feedback 	= feedback[0].feedback
                    }
                }
            })
            .catch(e => {
                this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
            })
        },
        AddEmail() {
        const trimmedEmail = this.form.email.trim();
        if (trimmedEmail && this.ValidateEmail(trimmedEmail)) {
            if (!this.emails.includes(trimmedEmail)) {
            this.emails.push(trimmedEmail);
            }
            this.form.email = '';
        }
        },
        RemoveEmail(index) {
            this.emails.splice(index, 1);
        },
        SearchGet() {
            this.loading.certificate = true
            let formdata = {
                uploadheader_batchno: this.search.batchno,
                uploadheader_mapcode: this.search.mapcode,
                uploadheader_campaignid: this.search.campaignid,
                order_by: "uploadheader_batchno",
                order_type: "ASC",
                limit: this.limit,
            }
            let param = this.$functions.ParamPOST(formdata)
            this.$axios
            .post(this.$functions.UrlPOST("apiListUploadHeader"), param, {
            headers: {
                "Content-Type": "text/plain charset=ISO-8859-1",
            },
            })
            .then((response) => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === "Y") {
                    this.items[this.search_title] = feedback
                    this.loading.certificate = false
                } else {
                    this.items[this.search_title] = feedback
                    this.loading.certificate = false
                }
            } else {
                this.items[this.search_title] = feedback
                this.loading.certificate = false
            }
            })
        },
        DownloadFailed(batchno) {
            this.loading.certificate = true
            let formdata = {
                uploadup_batchno: batchno,
                uploadup_download: 'Y',
                order_by: "uploadup_row",
                order_type: "ASC",
                limit: this.limit,
            }
            let param = this.$functions.ParamPOST(formdata)
            this.$axios
            .post(this.$functions.UrlPOST("apiListUploadDetail"), param, {
            headers: {
                "Content-Type": "text/plain charset=ISO-8859-1",
            },
            })
            .then((response) => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === "Y") {
                    window.open(feedback[0].uploadup_url_path)
                    this.loading.certificate = false
                } else {
                    this.SnackBar(true, "error", feedback[0].feedback, 0)
                    this.loading.certificate = false
                }
            } else {
                this.loading.certificate = false
            }
            })
        },
        GetCampaign(value) {
            let split = value.split('|')
            this.form.campaign_id = split[0]
            this.form.campaign_desc = split[1]
        },
        ValidateEmail(email) {
            // Basic email validation using regex
            const emailPattern = /\S+@\S+\.\S+/;
            return emailPattern.test(email);
        },
        SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
		},
    }
}
</script>